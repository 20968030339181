import React from 'react'
import { BrowserRouter as Router, Route, Switch, Redirect, } from 'react-router-dom'

import './App.css'
import SignIn from './pages/SignIn'
import SignUp from './pages/SignUp'
import HomePage from './pages/HomePage'

class App extends React.Component {
    constructor(props) {
        super(props)
    }

    componentWillMount() {

    }

    render() {
        return (
            <Router>
                <Switch>
                    <Route exact path="/sign_up" component={SignUp} />
                    <Route exact path="/sign_in" component={SignIn} />
                    <Route exact path="/" component={HomePage} />
                    <Redirect to="/" />
                </Switch>
            </Router>
        )
    }
}

export default App
