import React, { useEffect } from 'react';
import { useHistory } from "react-router-dom";
import PropTypes from 'prop-types';
import { AppBar, Tabs, Tab, Typography, Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import FinancialParser from './FinancialsTabs/FinancialParser';
import FinancialSummary from './FinancialsTabs/FinancialSummary';


function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`nav-tabpanel-${index}`}
            aria-labelledby={`nav-tab-${index}`}
            {...other}>
            {value === index && (
                <Box p={3} className='p-0'>
                    <Typography component={'span'}>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};


const tabHeight = '2.4rem'
const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper,
    },
    tabsRoot: {
        minHeight: tabHeight,
        height: tabHeight
    },
    tabRoot: {
        minHeight: tabHeight,
        height: tabHeight

    }
}));

export default function Financials() {

    let history = useHistory();
    useEffect(() => {

    }, []);

    const classes = useStyles();
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const signOut = async () => {
        setTimeout(() => {
            localStorage.clear();
            history.replace("/sign_in");
        }, 500)
    };

    return (
        <div className='container-fluid scroll' style={{ height: 'calc(100vh - 5rem)' }}>
            <div className='row sticky-top'>
                <div className='col-md-8 pt-3'>
                    <AppBar position="static" color='default' className='app_bar'>
                        <Tabs
                            className={classes.tabsRoot}
                            indicatorColor="primary"
                            textColor="inherit"
                            variant="fullWidth"
                            scrollButtons="auto"
                            value={value}
                            TabIndicatorProps={{
                                style: { background: '#357a38' }
                            }}
                            onChange={handleChange} >
                            <Tab component="div" className={classes.tabRoot} label={<span style={{ fontWeight: 'bold', fontSize: 'small' }}>Financial Spreading for Credit</span>} />
                            <Tab component="div" className={classes.tabRoot} label={<span style={{ fontWeight: 'bold', fontSize: 'small' }}>Financial Summary</span>} />
                        </Tabs>
                    </AppBar>
                </div>
            </div>
            <TabPanel value={value} index={0}>
                <FinancialParser />
            </TabPanel>
            <TabPanel value={value} index={1}>
                <FinancialSummary />
            </TabPanel>
        </div>
    );
}