import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { AppBar, Tabs, Tab, Toolbar, IconButton, Tooltip, Typography, Box, Drawer, List, ListItem, ListItemIcon, ListItemText, Divider, Slide } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { Close, PowerSettingsNew, Menu, OpenInNew, HomeSharp, Info, Call } from '@material-ui/icons'
import client_logo from '../images/walnut_logo.png'
import ContractReader from './HomeTabs/ContractReader';
import NewsReader from './HomeTabs/NewsReader';
import Financials from './HomeTabs/Financials'
import LanguageTranslation from './HomeTabs/LanguageTranslation';
import OurProducts from './HomeTabs/OurProducts';
import DGWallet from './HomeTabs/DGWallet';
import BankStatements from './HomeTabs/BankStatements';


function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`nav-tabpanel-${index}`}
            aria-labelledby={`nav-tab-${index}`}
            {...other}>
            {value === index && (
                <Box p={3} className='p-0'>
                    <Typography component={'span'}>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

const useStyles = theme => ({
    root: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper,
    },
    tabs: {
        flexGrow: 1,
        alignSelf: 'flex-end'
    },
});

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="left" ref={ref} {...props} />;
});

const PG_TRAINING_FLOW = 'pg_training_flow'
const PG_USER_MANIPULATION = 'pg_user_manipulation'

class HomePage extends Component {

    constructor(props) {
        super(props);
    }

    state = {
        tab_value: 0,
        pages: {},
        drawer_open: false,
        dialog_open: false,
    }

    componentDidMount() {
        this.validateSignedIn()
    }

    componentWillUnmount() {
        // fix Warning: Can't perform a React state update on an unmounted component
        this.setState = () => {
            return;
        };
    }

    validateSignedIn = async () => {
        if (localStorage.getItem('LOGEDIN') !== 'true') {
            this.props.history.replace("/sign_in");
        }
        else {
            this.addPages()
        }
    }

    addPages = () => {
        let pages = {}
        pages[PG_TRAINING_FLOW] = true
        pages[PG_USER_MANIPULATION] = true
        this.setState({ pages: pages })
    }

    handleTabs = (evt, tab_value) => {
        this.setState({ tab_value: tab_value })
    }

    drawerOpen = () => {
        this.setState({ drawer_open: true })
    }

    drawerClose = () => {
        this.setState({ drawer_open: false })
    }

    dialogOpen = () => {
        this.setState({ dialog_open: true })
    }

    dialogClose = () => {
        this.setState({ dialog_open: false })
    }

    openPage = (page) => {
        let pages = this.state.pages
        Object.keys(pages).forEach((k, i) => {
            if (k === page) {
                pages[k] = false
            }
            else {
                pages[k] = true
            }
        });
        this.setState({ pages: pages })
        this.drawerClose()
        this.dialogOpen()
    }

    aboutUs = () => {
        this.drawerClose()
        window.open('https://nityodeeptech.one/')
    }

    signOut = async () => {
        localStorage.clear();
        this.props.history.replace("/sign_in");
    }

    render() {
        return (
            <div className='vh-100 scroll_hidden'>
                <AppBar position="sticky" color='default'>
                    <Toolbar className='bg-white pr-0 pl-1'>
                        <img src={client_logo} style={{ height: '5rem' }} className='p-2' />
                        <Tabs
                            className={this.props.classes.tabs}
                            style={{ alignSelf: 'flex-end' }}
                            indicatorColor="primary"
                            textColor="primary"
                            variant="scrollable"
                            scrollButtons="on"
                            value={this.state.tab_value}
                            onChange={this.handleTabs} >
                            <Tab component="div" label={<span style={{ fontWeight: 'bold' }}>Contract Reader</span>} />
                            <Tab component="div" label={<span style={{ fontWeight: 'bold' }}>News Reader</span>} />
                            <Tab component="div" label={<span style={{ fontWeight: 'bold' }}>Financials</span>} />
                            <Tab component="div" label={<span style={{ fontWeight: 'bold' }}>Bank Statements</span>} />
                            <Tab component="div" label={<span style={{ fontWeight: 'bold' }}>DG Wallet</span>} />
                            <Tab component="div" label={<span style={{ fontWeight: 'bold' }}>Language Translation</span>} />
                            <Tab component="div" label={<span style={{ fontWeight: 'bold' }}>Our Products</span>} />
                        </Tabs>
                        <div className='pr-2 '>
                            <Tooltip title="MENU" placement="left-start" arrow>
                                <IconButton color='primary' className='' onClick={this.drawerOpen}>
                                    <Menu style={{ fontSize: '1.8rem' }} />
                                </IconButton>
                            </Tooltip>
                        </div>
                    </Toolbar>
                </AppBar>
                <div>
                    <TabPanel value={this.state.tab_value} index={0}>
                        <ContractReader />
                    </TabPanel>
                    <TabPanel value={this.state.tab_value} index={1}>
                        <NewsReader />
                    </TabPanel>
                    <TabPanel value={this.state.tab_value} index={2}>
                        <Financials />
                    </TabPanel>
                    <TabPanel value={this.state.tab_value} index={3}>
                        <BankStatements />
                    </TabPanel>
                    <TabPanel value={this.state.tab_value} index={4}>
                        <DGWallet />
                    </TabPanel>
                    <TabPanel value={this.state.tab_value} index={5}>
                        <LanguageTranslation />
                    </TabPanel>
                    <TabPanel value={this.state.tab_value} index={6}>
                        <OurProducts />
                    </TabPanel>
                </div>
                <Drawer
                    variant="temporary"
                    anchor="right"
                    open={this.state.drawer_open}
                    onClose={this.drawerClose}>
                    <AppBar position="sticky" color='default'>
                        <Toolbar className='' style={{ height: '5rem' }}>
                            <div style={{ flexGrow: 1 }} />
                            <div>
                                <Tooltip title="CLOSE" placement="left-start" arrow>
                                    <IconButton
                                        color='primary'
                                        edge='end'
                                        onClick={this.drawerClose}
                                        style={{ fontSize: '1.8rem' }} >
                                        <Close />
                                    </IconButton>
                                </Tooltip>
                            </div>
                        </Toolbar>
                    </AppBar>
                    <List className='mt-2'>
                        <ListItem button onClick={this.drawerClose}>
                            <ListItemIcon>
                                <HomeSharp />
                            </ListItemIcon>
                            <ListItemText color='primary' primary='Home' />
                        </ListItem>
                    </List>
                    <Divider />
                    <List className='mt-1'>
                        <ListItem button>
                            <ListItemIcon>
                                <Call />
                            </ListItemIcon>
                            <ListItemText color='primary' primary='Contact Us' />
                        </ListItem>
                        <ListItem button onClick={this.aboutUs}>
                            <ListItemIcon>
                                <Info />
                            </ListItemIcon>
                            <ListItemText color='primary' primary='About Us' />
                            <OpenInNew color='primary' style={{ fontSize: '1rem' }} className='ml-4 mr-4' />
                        </ListItem>
                    </List>
                    <Divider />
                    <List className='mt-1'>
                        <ListItem button onClick={this.signOut}>
                            <ListItemIcon>
                                <PowerSettingsNew />
                            </ListItemIcon>
                            <ListItemText primary='Sign Out' />
                        </ListItem>
                    </List>
                </Drawer>
            </div>
        )
    }
}

export default withStyles(useStyles)(HomePage)
