import React, { Component } from "react";
import { Backdrop, CircularProgress, FormControl, InputLabel, Select, MenuItem, Fab } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { useStyles } from '../../../custom_libraries/customStyles';


class FinancialSummary extends Component {

    constructor(props) {
        super(props);
        this.file_chooser = React.createRef();
    }

    state = {
        company_name_list: [],
        company_name: '',

        input_file: '',
        output_file: '',

        open_progress_bar: false,
        spinner_hidden: true,
        button_disabled: false,
    }

    componentDidMount() {
        this.getCompanyName()
    }

    componentWillUnmount() {
        // fix Warning: Can't perform a React state update on an unmounted component
        this.setState = () => {
            return;
        };
    }

    timeout = (delay) => {
        return new Promise(res => setTimeout(res, delay));
    }

    handleCompanyName = (evt) => {
        let company_name = evt.target.value;
        if (company_name !== null || company_name !== undefined || company_name !== '') {
            this.setState({ company_name: company_name });
            this.getOutputFiles(company_name)
        }
    }

    getCompanyName = async () => {
        this.startProgressBar()
        let company_name_list = ['Aercap', 'Apple', 'CSC Holdings', 'Japfa', 'Koufu', 'Sembcorp']
        this.setState({ company_name_list: company_name_list })
        this.stopProgressBar()
    }

    startProgressBar = () => {
        this.setState({ open_progress_bar: true });
        this.setState({ button_disabled: true })
        this.setState({ spinner_hidden: false })
    }

    stopProgressBar = () => {
        this.setState({ open_progress_bar: false });
        this.setState({ button_disabled: false })
        this.setState({ spinner_hidden: true })
    }

    getOutputFiles = async (company_name) => {
        this.startProgressBar()
        // Delay of 8 Seconds
        await this.timeout(8000);
        this.setState({ input_file: 'static/financial_summary/' + company_name + '_input.pdf#toolbar=0' })
        this.setState({ output_file: 'static/financial_summary/' + company_name + '.pdf#toolbar=0' })
        this.stopProgressBar()
    }

    render() {
        return (
            <div className="pt-2">
                <div className='row'>
                    <div className='col-md-6'>
                        <FormControl size="small" variant="outlined" className="w-100 mt-2" disabled={this.state.button_disabled}>
                            <InputLabel>Company Name</InputLabel>
                            <Select value={this.state.company_name} onChange={this.handleCompanyName} label="Company Name">
                                {this.state.company_name_list.map((k, i) => (
                                    <MenuItem key={k} value={this.state.company_name_list[i]}>
                                        {this.state.company_name_list[i]}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        <embed
                            className='w-100 border mt-2 scroll'
                            style={{ height: 'calc(100vh - 13rem)' }}
                            src={this.state.input_file}
                            key={this.state.input_file}
                        />
                    </div>
                    <div className='col-md-6'>
                        <div className='mt-2'>
                            <b className='text-secondary pl-2'>Output</b>
                            <Fab
                                size="small"
                                className='small_button text-white  mr-2'
                                style={{ visibility: 'hidden' }} >
                            </Fab>
                        </div>
                        <embed
                            className='w-100 border mt-2 scroll'
                            style={{ height: 'calc(100vh - 13rem)' }}
                            src={this.state.output_file}
                            key={this.state.output_file}
                        />
                    </div>
                </div>
                <Backdrop className={this.props.classes.backdrop} open={this.state.open_progress_bar}>
                    <CircularProgress size={80} color="inherit" />
                </Backdrop>
            </div>
        )
    }
}

export default withStyles(useStyles)(FinancialSummary);