import React, { Component } from "react";
import exportFromJSON from 'export-from-json'; // npm i --save export-from-json
import { ListItem, ListItemText, Button, Tooltip, Fab, CircularProgress, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Paper, LinearProgress, FormControl, InputLabel, Select, MenuItem, ListItemIcon, Collapse, Backdrop } from '@material-ui/core';
import { ExpandLess, ExpandMore, GetApp } from '@material-ui/icons'
import graph_reader_json from '../../../jsons/graph_reader.json';
import { Description } from "@material-ui/icons";
import { withStyles } from '@material-ui/core/styles';
import { useStyles } from '../../../custom_libraries/customStyles';


class GraphReader extends Component {

    constructor(props) {
        super(props);
        this.file_chooser = React.createRef();
    }

    state = {
        company_name_list: [],
        company_name: '',

        file_name: '',

        all_data: [],
        list_expand: [false, false],

        open_progress_bar: false,
        download_hidden: true,
    }

    componentDidMount() {
        this.getCompanyName()
    }

    componentWillUnmount() {
        // fix Warning: Can't perform a React state update on an unmounted component
        this.setState = () => {
            return;
        };
    }

    timeout = (delay) => {
        return new Promise(res => setTimeout(res, delay));
    }

    handleCompanyName = (evt) => {
        let company_name = evt.target.value;
        if (company_name !== null || company_name !== undefined || company_name !== '') {
            this.setState({ company_name: company_name });
            this.getOutputFiles(company_name)
        }
    }

    getCompanyName = async () => {
        this.startLoading()
        let company_name_list = ['FHT']
        this.setState({ company_name_list: company_name_list })
        this.stopLoading()
    }

    getOutputFiles = async (company_name) => {

        this.startLoading()
        // let headers = {
        //     'content-type': 'application/json',
        // }

        // let data = await get(urls.ANNUAL_REPORT_EXTRACTION, headers)

        // if (data !== false) {
        //     // console.log(data)
        // }

        // Delay of 8 Seconds
        await this.timeout(5000);
        let file_name = 'static/contract_reader/graph_reader/' + company_name + '.jpg'
        this.setState({ file_name: file_name })
        this.setState({ all_data: graph_reader_json[company_name] })
        this.setState({ download_hidden: false })
        this.stopLoading()
    }

    onExpand = (index) => {
        let list_expand = this.state.list_expand
        list_expand[index] = !list_expand[index]
        this.setState({ list_expand: list_expand })
    }

    downloadReport = () => {
        const data = this.state.all_data
        const fileName = this.state.company_name
        const exportType = 'xls'
        exportFromJSON({ data, fileName, exportType })
    }

    startLoading = () => {
        this.setState({ open_progress_bar: true });
    }

    stopLoading = () => {
        this.setState({ open_progress_bar: false });
    }

    render() {
        return (
            <div className="pt-2">
                <div className='row'>
                    <div className='col-md-6'>
                        <FormControl size="small" variant="outlined" className="w-100 mt-2">
                            <InputLabel>Company Name</InputLabel>
                            <Select value={this.state.company_name} onChange={this.handleCompanyName} label="Company Name">
                                {this.state.company_name_list.map((k, i) => (
                                    <MenuItem key={k} value={this.state.company_name_list[i]}>
                                        {this.state.company_name_list[i]}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        <iframe
                            className='w-100 border mt-2 scroll'
                            style={{ height: 'calc(100vh - 13rem)' }}
                            src={this.state.file_name}
                            key={this.state.file_name}
                        />
                    </div>
                    <div className='col-md-6'>
                        <div className='text-right'>
                            <Tooltip title="Download Report" placement='left-start' arrow>
                                <Fab
                                    size="small"
                                    className='small_button text-white mt-2 mr-2'
                                    onClick={() => this.downloadReport()}
                                    hidden={this.state.download_hidden} >
                                    <GetApp fontSize='small' />
                                </Fab>
                            </Tooltip>
                            <Fab
                                size="small"
                                className='small_button text-white mt-2 mr-2'
                                style={{ visibility: 'hidden' }}
                                hidden={!this.state.download_hidden} >
                                <GetApp fontSize='small' />
                            </Fab>
                        </div>
                        <TableContainer component={Paper} className='border mt-2 scroll' hidden={this.state.download_hidden} style={{ height: 'calc(100vh - 13rem)' }}>
                            <Table size="medium" stickyHeader>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>
                                            <b></b>
                                        </TableCell>
                                        <TableCell align="right">
                                            <b>FY 2019 GR</b>
                                        </TableCell>
                                        <TableCell align="right">
                                            <b>FY 2020 GR</b>
                                        </TableCell>
                                        <TableCell align="right">
                                            <b>FY 2019 NPI</b>
                                        </TableCell>
                                        <TableCell align="right">
                                            <b>FYI 2020 NPI</b>
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {Object.keys(this.state.all_data).map((k, i) => (
                                        <TableRow>
                                            <TableCell>
                                                <b>{this.state.all_data[i]['Country']}</b>
                                            </TableCell>
                                            <TableCell align="right">
                                                {this.state.all_data[i]['FY 2019 GR']}
                                            </TableCell>
                                            <TableCell align="right">
                                                {this.state.all_data[i]['FY 2020 GR']}
                                            </TableCell>
                                            <TableCell align="right">
                                                {this.state.all_data[i]['FY 2019 NPI']}
                                            </TableCell>
                                            <TableCell align="right">
                                                {this.state.all_data[i]['FYI 2020 NPI']}
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </div>
                </div>
                <Backdrop className={this.props.classes.backdrop} open={this.state.open_progress_bar}>
                    <CircularProgress size={80} color="inherit" />
                </Backdrop>
            </div >
        )
    }
}

export default withStyles(useStyles)(GraphReader);