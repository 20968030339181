import React, { Component } from "react";
import exportFromJSON from 'export-from-json'; // npm i --save export-from-json
import { TextField, Button, FormControl, InputLabel, LinearProgress, Select, CircularProgress, MenuItem, Fab, ListItemText, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Paper, Tooltip, Backdrop } from '@material-ui/core';
import { AttachFile, CheckCircle, Cancel, GetApp, Description, Label, Check } from '@material-ui/icons'
import { withStyles } from '@material-ui/core/styles';
import { isEmpty } from '../../custom_libraries/validation';
import dg_wallet from '../../jsons/dg_wallet.json';
import { useStyles } from '../../custom_libraries/customStyles';


class DGWallet extends Component {

    constructor(props) {
        super(props);
    }

    state = {
        selected_form: '',
        selected_passport: '',
        selected_other_doc: '',
        form_src: '',
        passport_src: '',
        other_doc_src: '',
        filled_form_src: '',

        open_progress_bar: false,
    }

    form_list = ['[Form] AmEx.pdf', '[Form] BOA.pdf', '[Form] BOC.pdf', '[Form] HSBC.pdf', '[Form] ICBC.pdf']
    passport_list = ['[Passport] Chinese.png', '[Passport] Singapore.jpg', '[Passport] Singapore1.jpg']
    other_docs_list = ['Utility Bill.png']

    componentWillUnmount() {
        // fix Warning: Can't perform a React state update on an unmounted component
        this.setState = () => {
            return;
        };
    }

    handleForm = (evt) => {
        let selected_form = evt.target.value
        let form_src = 'static/dg_wallet/forms/' + selected_form
        this.setState({
            selected_form: selected_form,
            form_src: form_src,
            filled_form_src: ''
        })
    }

    handlePassport = async (evt) => {
        if (this.state.other_doc_src !== '') {
            this.startLoading()
            await this.timeout(1000);
            this.stopLoading()
        }
        let selected_passport = evt.target.value
        let passport_src = 'static/dg_wallet/passports/' + selected_passport
        this.setState({
            selected_passport: selected_passport,
            passport_src: passport_src,
            filled_form_src: ''
        })
    }

    handleOtherDoc = async (evt) => {
        if (this.state.passport_src !== '') {
            this.startLoading()
            await this.timeout(1000);
            this.stopLoading()
        }
        let selected_other_doc = evt.target.value
        let other_doc_src = 'static/dg_wallet/other_docs/' + selected_other_doc
        this.setState({
            selected_other_doc: selected_other_doc,
            other_doc_src: other_doc_src,
        })
    }

    isEmptyFields = () => {
        let data = [
            this.state.selected_form, this.state.selected_passport,
            this.state.other_doc_src
        ]
        return isEmpty(data)
    }

    jsonData = () => {
        let data = {
            'pdfform_filename': this.state.selected_form,
            'id_filename': this.state.selected_passport
        }
        return JSON.stringify(data)
    }

    fillForm = async () => {
        if (this.isEmptyFields()) {
            alert("Fill All Fields !!!")
            return
        }
        this.startLoading()
        await this.timeout(5000);
        let selected_form = this.state.selected_form
        let selected_passport = this.state.selected_passport
        let filled_form_src = dg_wallet[selected_passport][selected_form]
        this.setState({
            filled_form_src: '/static/dg_wallet/' + filled_form_src
        })
        this.stopLoading()
    }

    timeout = (delay) => {
        return new Promise(res => setTimeout(res, delay));
    }

    startLoading = () => {
        this.setState({ open_progress_bar: true });
    }

    stopLoading = () => {
        this.setState({ open_progress_bar: false });
    }

    render() {
        return (
            <div className="container-fluid pt-3">
                <div className='row'>
                    <div className='col-md-4'>
                        <FormControl size="small" variant="outlined" className="w-100 mt-2">
                            <InputLabel>Choose Form</InputLabel>
                            <Select value={this.state.selected_form} onChange={this.handleForm} label="Choose Form">
                                {Object.keys(this.form_list).map((k, i) => (
                                    <MenuItem key={k} value={this.form_list[i]}>
                                        {this.form_list[i]}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        <iframe key={this.state.form_src} src={this.state.form_src} className='w-100 border mt-2' style={{ height: 'calc(100vh - 10rem)' }} />
                    </div>
                    <div className='col-md-4'>
                        <div>
                            <FormControl size="small" variant="outlined" className="w-100 mt-2">
                                <InputLabel>Choose Passport</InputLabel>
                                <Select value={this.state.selected_passport} onChange={this.handlePassport} label="Choose Passport">
                                    {Object.keys(this.passport_list).map((k, i) => (
                                        <MenuItem key={k} value={this.passport_list[i]}>
                                            {this.passport_list[i]}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                            <iframe key={this.state.passport_src} src={this.state.passport_src} className='w-100 border mt-2' style={{ height: 'calc(50vh - 9rem)' }} />
                        </div>
                        <div>
                            <FormControl size="small" variant="outlined" className="w-100 mt-2">
                                <InputLabel>Choose Other Document</InputLabel>
                                <Select value={this.state.selected_other_doc} onChange={this.handleOtherDoc} label="Choose Other Document">
                                    {Object.keys(this.other_docs_list).map((k, i) => (
                                        <MenuItem key={k} value={this.other_docs_list[i]}>
                                            {this.other_docs_list[i]}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                            <iframe key={this.state.other_doc_src} src={this.state.other_doc_src} className='w-100 border mt-2' style={{ height: 'calc(50vh - 9rem)' }} />
                        </div>
                        <div className='border mt-2 pl-2' style={{ height: '3.1rem' }}>
                            {
                                this.state.selected_passport === '[Passport] Singapore.jpg' && this.state.selected_other_doc === 'Utility Bill.png'
                                    ?
                                    <div>
                                        <CheckCircle className='mr-2 text-success' fontSize='small' />
                                        <b style={{ fontSize: '1rem' }} className='text-success'>Name Matched</b>
                                        <br />
                                        <i className='ml-4' style={{ color: 'orange' }}>! Bill more than 6 months old.</i>
                                    </div>
                                    :
                                    <div />
                            }
                            {
                                this.state.selected_passport !== '[Passport] Singapore.jpg' && this.state.selected_passport !== '' && this.state.selected_other_doc !== ''
                                    ?
                                    <div>
                                        <Cancel className='mr-2 text-danger' fontSize='small' />
                                        <b style={{ fontSize: '1rem' }} className='text-danger'>Name Not Matched</b><br />
                                        <i className='ml-4' style={{ color: 'orange' }}>! Bill more than 6 months old.</i>
                                    </div>
                                    :
                                    <div />
                            }
                        </div>
                    </div>
                    <div className='col-md-4'>
                        <Button
                            variant="contained"
                            size="large"
                            className="small_button mt-2"
                            style={{ height: '2.5rem' }}
                            onClick={this.fillForm}>
                            <b>Fill Form</b>
                            <Check className='ml-2' />
                        </Button>
                        <iframe key={this.state.filled_form_src} src={this.state.filled_form_src} className='w-100 border mt-2' style={{ height: 'calc(100vh - 10rem)' }} />
                    </div>
                </div>
                <Backdrop className={this.props.classes.backdrop} open={this.state.open_progress_bar}>
                    <CircularProgress size={80} color="inherit" />
                </Backdrop>
            </div>
        )
    }
}

export default withStyles(useStyles)(DGWallet)