import React, { Component } from "react";
import client_logo from '../images/walnut_logo.png'
import { Card, CardContent, TextField, Button, IconButton, CircularProgress, InputAdornment } from '@material-ui/core';
import { Email, LockOpen, VpnKey, Visibility, VisibilityOff } from '@material-ui/icons'
import { isEmpty } from '../custom_libraries/validation';

class SignIn extends Component {

    constructor(props) {
        super(props);
    }

    state = {
        email: '',
        password: '',
        confirm_password: '',
        is_show_password: false,

        spinner_hidden: true,
        button_disabled: false,
    }

    users = [
        {
            'email': 'yun@yun.buzz',
            'password': 'yun'
        },
        {
            'email': 'demo@nityo.com',
            'password': 'Demo@12345'
        },
        {
            'email': 'demo@thewalnut.ai',
            'password': 'Demo@12345'
        }
    ]

    componentDidMount() {
        if (localStorage.getItem('LOGEDIN') === 'true') {
            this.props.history.replace("/");
        }
    }

    componentWillUnmount() {
        // fix Warning: Can't perform a React state update on an unmounted component
        this.setState = () => {
            return;
        };
    }

    timeout = (delay) => {
        return new Promise(res => setTimeout(res, delay));
    }

    handleEmail = (evt) => {
        this.setState({ email: evt.target.value });
    }

    handlePassword = (evt) => {
        this.setState({ password: evt.target.value });
    }

    startLoading = () => {
        this.setState({ button_disabled: true })
        this.setState({ spinner_hidden: false })
    }

    stopLoading = () => {
        this.setState({ button_disabled: false })
        this.setState({ spinner_hidden: true })
    }

    isEmptyFields = () => {

        let data = [this.state.email, this.state.password]

        return isEmpty(data)
    }

    formData = () => {
        let fd = new FormData();

        fd.append('email', this.state.email.trim());
        fd.append('password', this.state.password.trim());

        return fd
    }

    jsonData = () => {
        let data = {
            'email': this.state.email.trim(),
            'password': this.state.password.trim()
        }
        return JSON.stringify(data)
    }

    userLogin = async () => {
        if (this.isEmptyFields()) {
            alert("Fill All Fields !!!")
            return
        }
        this.startLoading()
        // Delay of 4 Seconds
        await this.timeout(4000);
        for (let user of this.users) {
            if (this.state.email === user['email'] && this.state.password === user['password']) {
                localStorage.setItem('LOGEDIN', 'true')
                this.props.history.replace("/");
                this.stopLoading()
                return
            }
        }
        alert('Incorrect email or password !')
        this.stopLoading()
    }

    expiryTime = (hour) => {
        let now = new Date();
        now.setHours(now.getHours() + hour);
        return now
    }

    keyPress = (evt) => {
        if (evt.key === "Enter") {
            this.userLogin()
        }
    }


    render() {
        return (
            <div className="d-flex justify-content-center align-items-center h-100" style={{ minHeight: '100vh', backgroundColor: '#F0F4F3' }}>
                <div className="">
                    <Card>
                        <div className='row'>
                            <div className='col-sm-7 pr-0'>
                                <CardContent className='p-0'>
                                    <div className='text-center'>
                                        <img src={client_logo} style={{ height: '5rem' }} className='p-2' />
                                    </div>
                                    <div className='p-2'>
                                        <div className="pt-1 text-center">
                                            <TextField
                                                label="Email"
                                                size="small"
                                                variant="outlined"
                                                className="w-75"
                                                value={this.state.email}
                                                onChange={this.handleEmail}
                                                onKeyPress={this.keyPress}
                                                InputProps={{
                                                    startAdornment:
                                                        <InputAdornment position="start">
                                                            <Email color="action" className="mr-2" />
                                                        </InputAdornment>,
                                                }}
                                            />
                                        </div>

                                        <div className="pt-3 text-center">
                                            <TextField
                                                label="Password"
                                                type={this.state.is_show_password ? 'text' : 'password'}
                                                variant="outlined"
                                                size="small"
                                                className="w-75"
                                                value={this.state.password}
                                                onChange={this.handlePassword}
                                                onKeyPress={this.keyPress}
                                                InputProps={{
                                                    startAdornment:
                                                        <InputAdornment position="start">
                                                            <VpnKey color="action" className="mr-2" />
                                                        </InputAdornment>,

                                                    endAdornment:
                                                        <InputAdornment position="end">
                                                            <IconButton
                                                                aria-label="toggle password visibility"
                                                                onClick={() => this.setState({ is_show_password: !this.state.is_show_password })}
                                                            >
                                                                {this.state.is_show_password ? <Visibility /> : <VisibilityOff />}
                                                            </IconButton>
                                                        </InputAdornment>,
                                                }}
                                            />
                                        </div>
                                        <div className="pt-4 pb-3 text-center">
                                            <Button
                                                variant="contained"
                                                size="large"
                                                className="login_button"
                                                onClick={this.userLogin}
                                                disabled={this.state.button_disabled}>
                                                <CircularProgress className="text-white mr-2" size={24} hidden={this.state.spinner_hidden} />
                                                <LockOpen className="mr-2" />
                                                <b>SIGN IN</b>
                                            </Button>
                                        </div>
                                    </div>
                                </CardContent>
                            </div>
                            <div className='col-sm-5 text-center d-flex justify-content-center align-items-center pl-0' style={{ backgroundColor: '#3AB397' }}>
                                <div className='pt-5 pb-5 pl-4 pr-4'>
                                    <div className='welcome_text'>Hello, Friend!</div>
                                    <div className='text-white'>Enter your personal details and &nbsp;&nbsp;&nbsp;<br />start journey with us</div>
                                    <div className='pt-4'>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Card>
                </div>
            </div>
        );
    }
}

export default SignIn;

