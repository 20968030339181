import React, { Component } from "react";


class OurProducts extends Component {

    constructor(props) {
        super(props);
        this.file_chooser = React.createRef();
    }

    state = {
        file_name: 'static/our_products/Yun_Feb2020_OurProducts.pdf#toolbar=0',
    }

    componentDidMount() {
    }

    componentWillUnmount() {
        // fix Warning: Can't perform a React state update on an unmounted component
        this.setState = () => {
            return;
        };
    }

    timeout = (delay) => {
        return new Promise(res => setTimeout(res, delay));
    }

    startProgressBar = () => {
        this.setState({ is_progressar_hidden: false });
        this.setState({ button_disabled: true })
        this.setState({ spinner_hidden: false })
    }

    stopProgressBar = () => {
        this.setState({ is_progressar_hidden: true });
        this.setState({ button_disabled: false })
        this.setState({ spinner_hidden: true })
    }

    render() {
        return (
            <div className="container-fluid pt-2">
                <div className='row'>
                    <div className='col-md-12'>
                        <iframe
                            className='w-100 border mt-2 scroll'
                            style={{ height: 'calc(100vh - 7.5rem)' }}
                            src={this.state.file_name}
                            key={this.state.file_name}
                        />
                    </div>
                </div>
            </div>
        )
    }
}

export default OurProducts;