import React, { Component } from "react";
import exportFromJSON from 'export-from-json'; // npm i --save export-from-json
import { Backdrop, CircularProgress, TableContainer, Table, TableRow, TableCell, TableBody, Paper, FormControl, InputLabel, Select, MenuItem, Tooltip, Fab } from '@material-ui/core';
import { GetApp } from '@material-ui/icons'
import { post } from '../../../custom_libraries/serverRequests';
import urls from '../../../custom_libraries/urls';
import { withStyles } from '@material-ui/core/styles';
import { useStyles } from '../../../custom_libraries/customStyles';


class TermsSheets extends Component {
    constructor(props) {
        super(props);
        this.file_chooser = React.createRef();
    }

    state = {
        company_name_list: [],
        company_name: '',

        file_name: '',

        sec_code: '----',
        bank_name: '----',
        isin_code: '----',
        currency: '----',
        issue: '----',
        trade_date: '----',
        maturity_date: '----',
        capital_protection: '----',
        all_data: [],

        open_progress_bar: false,
        spinner_hidden: true,
        button_disabled: false,
        download_hidden: true,
    }

    componentDidMount() {
        this.getCompanyName()
    }

    componentWillUnmount() {
        // fix Warning: Can't perform a React state update on an unmounted component
        this.setState = () => {
            return;
        };
    }

    timeout = (delay) => {
        return new Promise(res => setTimeout(res, delay));
    }

    handleCompanyName = (evt) => {
        let company_name = evt.target.value;
        if (company_name !== null || company_name !== undefined || company_name !== '') {
            this.setState({ company_name: company_name });
            this.extractInformation(company_name)
        }
    }

    startProgressBar = () => {
        this.setState({ open_progress_bar: true });
        this.setState({ button_disabled: true })
        this.setState({ spinner_hidden: false })
    }

    stopProgressBar = () => {
        this.setState({ open_progress_bar: false });
        this.setState({ button_disabled: false })
        this.setState({ spinner_hidden: true })
    }

    getCompanyName = async () => {
        this.startProgressBar()
        let company_name_list = ['115331-000', '115332-000', '116965-000', '116986-000', '120033-000', '120238-000', '122115-000', '122121-000', '122615-000', '122636-000']
        this.setState({ company_name_list: company_name_list })
        this.stopProgressBar()
    }

    extractInformation = async (company_name) => {
        this.startProgressBar()
        let headers = {
            'content-type': 'application/json',
        }
        let post_data = JSON.stringify({
            'sec_code': company_name
        })

        let data = await post(urls.CONTRACT_READER, headers, post_data)

        // Delay of 5 Seconds
        await this.timeout(5000);

        if (data !== false) {
            // console.log(data)
            this.setState({ file_name: 'static/contract_reader/terms_sheets/' + data['Sec Code'] + '.pdf#toolbar=0' })
            this.setState({ sec_code: data['Sec Code'] })
            this.setState({ bank_name: data['Bank Name'] })
            this.setState({ isin_code: data['ISIN Code'] })
            this.setState({ currency: data['Currency'] })
            this.setState({ issue: data['Issue'] })
            this.setState({ trade_date: data['Trade Date'] })
            this.setState({ maturity_date: data['Maturity Date'] })
            this.setState({ capital_protection: data['Capital Protection'] })

            this.setState({ all_data: data })
            this.setState({ download_hidden: false })
        }
        this.stopProgressBar()
    }

    downloadReport = () => {
        const data = [this.state.all_data]
        const fileName = this.state.all_data['Sec Code']
        const exportType = 'xls'
        exportFromJSON({ data, fileName, exportType })
    }

    render() {
        return (
            <div className="pt-2">
                <div className='row'>
                    <div className='col-md-6'>
                        <FormControl size="small" variant="outlined" className="w-100 mt-2" disabled={this.state.button_disabled}>
                            <InputLabel>Company Name</InputLabel>
                            <Select value={this.state.company_name} onChange={this.handleCompanyName} label="Company Name">
                                {this.state.company_name_list.map((k, i) => (
                                    <MenuItem key={k} value={this.state.company_name_list[i]}>
                                        {this.state.company_name_list[i]}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        <embed
                            className='w-100 border mt-2 scroll'
                            style={{ height: 'calc(100vh - 13rem)' }}
                            src={this.state.file_name}
                            key={this.state.file_name}
                        />
                    </div>
                    <div className='col-md-6'>
                        <div className='text-right'>
                            <Tooltip title="Download Report" placement='left-start' arrow>
                                <Fab
                                    size="small"
                                    className='small_button text-white mt-2 mr-2'
                                    onClick={() => this.downloadReport()}
                                    disabled={this.state.button_disabled}
                                    hidden={this.state.download_hidden} >
                                    <GetApp fontSize='small' />
                                </Fab>
                            </Tooltip>
                            <Fab
                                size="small"
                                className='small_button text-white mt-2 mr-2'
                                style={{ visibility: 'hidden' }}
                                hidden={!this.state.download_hidden} >
                                <GetApp fontSize='small' />
                            </Fab>
                        </div>
                        <TableContainer component={Paper} className='border mt-2 scroll' style={{ height: 'calc(100vh - 13rem)' }}>
                            <Table size="medium">
                                <TableBody>
                                    <TableRow>
                                        <TableCell>
                                            <b>Sec Code</b>
                                        </TableCell>
                                        <TableCell align="left">
                                            {this.state.sec_code}
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>
                                            <b>Bank Name</b>
                                        </TableCell>
                                        <TableCell align="left">
                                            {this.state.bank_name}
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>
                                            <b>ISIN Code</b>
                                        </TableCell>
                                        <TableCell align="left">
                                            {this.state.isin_code}
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>
                                            <b>Currency</b>
                                        </TableCell>
                                        <TableCell align="left">
                                            {this.state.currency}
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>
                                            <b>Issue</b>
                                        </TableCell>
                                        <TableCell align="left">
                                            {this.state.issue}
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>
                                            <b>Trade Date</b>
                                        </TableCell>
                                        <TableCell align="left">
                                            {this.state.trade_date}
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>
                                            <b>Maturity Date</b>
                                        </TableCell>
                                        <TableCell align="left">
                                            {this.state.maturity_date}
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>
                                            <b>Capital Protection</b>
                                        </TableCell>
                                        <TableCell align="left">
                                            {this.state.capital_protection}
                                        </TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </div>
                </div>
                <Backdrop className={this.props.classes.backdrop} open={this.state.open_progress_bar}>
                    <CircularProgress size={80} color="inherit" />
                </Backdrop>
            </div>
        )
    }
}

export default withStyles(useStyles)(TermsSheets)