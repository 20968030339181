import React, { Component } from "react";
import { Tooltip, Fab, CircularProgress, FormControl, InputLabel, Select, MenuItem, Backdrop } from '@material-ui/core';
import { GetApp } from '@material-ui/icons'
import { withStyles } from '@material-ui/core/styles';
import { useStyles } from '../../custom_libraries/customStyles';

const SPREAD_SHEET = 'https://docs.google.com/spreadsheets/d/e/2PACX-1vRzctan2NtIVoH7ZrVr4h0i1w1zB4DMNxh1cyVKC2ggYLF3xro-YwuCirEEG3tPGTJdv4bJQSKGEfWu'
class BankStatements extends Component {

    constructor(props) {
        super(props);
        this.file_chooser = React.createRef();
    }

    state = {
        company_name_list: [],
        company_name: '',
        sheet_id: '',

        files: [],
        file_name: '',
        excel_name: '',

        open_progress_bar: false,
        spinner_hidden: true,
        button_disabled: false,
        download_hidden: true,
    }

    componentDidMount() {
        this.getCompanyName()
    }

    componentWillUnmount() {
        // fix Warning: Can't perform a React state update on an unmounted component
        this.setState = () => {
            return;
        };
    }

    timeout = (delay) => {
        return new Promise(res => setTimeout(res, delay));
    }

    companyNameBySheetId = (sheet_id) => {
        let company_list = this.state.company_name_list.filter((c) => { return c.sheet_id == sheet_id });
        return (company_list[0].company_name)
    }

    handleCompanyName = (evt) => {
        let sheet_id = evt.target.value;
        let company_name = this.companyNameBySheetId(sheet_id)
        if (company_name !== null || company_name !== undefined || company_name !== '') {
            this.setState({ sheet_id: sheet_id, })
            this.getOutputFiles(company_name, sheet_id)
        }
    }

    getCompanyName = async () => {
        this.startProgressBar()
        let company_name_list = [
            {
                'company_name': 'HSBC (Statement - 1)',
                'sheet_id': '0'
            },
            {
                'company_name': 'HSBC (Statement - 2)',
                'sheet_id': '1522179199'
            },
            {
                'company_name': 'HSBC (Statement - 4)',
                'sheet_id': '954141545'
            },
            {
                'company_name': 'HSBC (Statement - 5)',
                'sheet_id': '654723466'
            }
        ]
        this.setState({ company_name_list: company_name_list })
        this.stopProgressBar()
    }

    startProgressBar = () => {
        this.setState({ open_progress_bar: true });
    }

    stopProgressBar = () => {
        this.setState({ open_progress_bar: false });
    }

    getOutputFiles = async (company_name, sheet_id) => {
        this.startProgressBar()
        // Delay of few Seconds
        await this.timeout(5000);
        let file_name = `static/bank_statements/${company_name}.pdf`
        let excel_name = `${SPREAD_SHEET}/pubhtml?gid=${sheet_id}&single=true&widget=true&headers=false&gridlines=false`
        this.setState({
            file_name: file_name + '#toolbar=0',
            excel_name: excel_name,
            download_hidden: false
        })
        this.stopProgressBar()
    }

    downloadReport = () => {
        window.open(`${SPREAD_SHEET}/pub?gid=${this.state.sheet_id}&single=true&output=xlsx`)
    }

    render() {
        return (
            <div className="container-fluid pt-3">
                <div className='row'>
                    <div className='col-md-6'>
                        <FormControl size="small" variant="outlined" className="w-100 mt-2">
                            <InputLabel>Company Name</InputLabel>
                            <Select value={this.state.sheet_id} onChange={this.handleCompanyName} label="Company Name">
                                {this.state.company_name_list.map((k, i) => (
                                    <MenuItem key={i} value={this.state.company_name_list[i]['sheet_id']}>
                                        {this.state.company_name_list[i]['company_name']}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        <iframe
                            className='w-100 border mt-2'
                            style={{ height: 'calc(100vh - 10rem)' }}
                            src={this.state.file_name}
                            key={this.state.file_name}
                        />
                    </div>
                    <div className='col-md-6'>
                        <div className='text-right'>
                            <Tooltip title="Download Report" placement='left-start' arrow>
                                <Fab
                                    size="small"
                                    className='small_button text-white mt-2 mr-2'
                                    onClick={() => this.downloadReport()}
                                    hidden={this.state.download_hidden}>
                                    <GetApp fontSize='small' />
                                </Fab>
                            </Tooltip>
                            <Fab
                                size="small"
                                className='small_button text-white mt-2 mr-2'
                                style={{ visibility: 'hidden' }}
                                hidden={!this.state.download_hidden} >
                                <GetApp fontSize='small' />
                            </Fab>
                        </div>
                        <div className="border mt-2" hidden={this.state.download_hidden} style={{ height: 'calc(100vh - 10rem)' }}>
                            <iframe
                                className='w-100 border'
                                src={this.state.excel_name}
                                key={this.state.excel_name}
                                style={{ height: 'calc(100vh - 10rem)' }}
                            />
                        </div>
                    </div>
                </div>
                <Backdrop className={this.props.classes.backdrop} open={this.state.open_progress_bar}>
                    <CircularProgress size={80} color="inherit" />
                </Backdrop>
            </div>
        )
    }
}

export default withStyles(useStyles)(BankStatements);