import React, { Component } from "react";
import exportFromJSON from 'export-from-json'; // npm i --save export-from-json
import { TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Paper, Backdrop, CircularProgress, Tooltip, Fab, FormControl, InputLabel, Select, MenuItem, Button, Card, FormControlLabel, Checkbox, Dialog, DialogTitle, DialogContent, DialogActions, TextField, InputAdornment, CardContent } from '@material-ui/core';
import { AddCircle, AttachFile, GetApp, Refresh, Send } from '@material-ui/icons'
import { get, post } from '../../../custom_libraries/serverRequests';
import urls from '../../../custom_libraries/urls';
import globalVariables from '../../../custom_libraries/globalVariables';
import { withStyles } from '@material-ui/core/styles';
import { useStyles } from '../../../custom_libraries/customStyles';


const initialState = {
    company_name_list: [],
    company_name: '',
    header_data: {
        'values': []
    },
    data: [{
        'key': '',
        'values': [],
        'type': [],
        'lines': []
    }],
    html_data: '',
    download_hidden: true,
    open_progress_bar: false,
}

class FinancialParser extends Component {

    constructor(props) {
        super(props);
        this.file_chooser = React.createRef();
    }

    state = { ...initialState }

    componentDidMount() {
        this.getCompanyName()
    }

    componentWillUnmount() {
        // fix Warning: Can't perform a React state update on an unmounted component
        this.setState = () => {
            return;
        };
    }

    getCompanyName = async () => {
        this.startLoading()
        let headers = {
            'content-type': 'application/json',
        }

        let data = await get(urls.CNMS_FINANCIAL_SPREADING, headers)

        if (data !== false) {
            // console.log(data)
            this.setState({ company_name_list: data })
        }
        this.stopLoading()
    }

    handleCompanyName = (evt) => {
        let company_name = evt.target.value;
        if (company_name !== null || company_name !== undefined || company_name !== '') {
            this.setState({ company_name: company_name });
            this.annualReportExtraction(company_name)
        }
    }

    annualReportExtraction = async (company_name) => {

        this.startLoading()
        let headers = {
            'content-type': 'application/json',
        }

        let post_data = JSON.stringify({ 'company_name': company_name })

        let response = await post(urls.ANNUAL_REPORT_EXTRACTION, headers, post_data)

        // Delay of 5 Seconds
        await this.timeout(5000);

        if (response !== false) {
            // console.log(data)
            if (response['status']) {
                let data = response['data']
                this.setState({
                    header_data: data[0],
                    data: data.slice(1, data.length),
                    html_data: "data:text/html," + encodeURIComponent(response['html']),
                    download_hidden: false
                })
            }
        }
        this.stopLoading()
    }

    jsonData = (type, lnum_list) => {
        let data = {
            'company_name': this.state.company_name,
            'type': type,
            'lnum_list': lnum_list
        }
        return JSON.stringify(data)
    }

    getHTMLFile = async (type, lnum_list) => {
        this.startLoading()
        let headers = {
            'content-type': 'application/json',
        }
        let post_data = this.jsonData(type, lnum_list)

        let data = await post(urls.ANNUAL_REPORT_EXTRACTION_GET_HTML, headers, post_data)

        if (data !== false) {
            // console.log(data)
            if (data.hasOwnProperty('html')) {
                this.setState({
                    html_data: "data:text/html," + encodeURIComponent(data['html'])
                })
            }
        }
        this.stopLoading()
    }

    downloadReport = () => {
        let download_data = []
        let column_names = this.state.header_data['values']
        let all_data = this.state.data
        for (let i = 0; i < all_data.length; i++) {
            let single_data = {}
            single_data[''] = all_data[i]['key']
            for (let j = 0; j < column_names.length; j++) {
                single_data[column_names[j]] = all_data[i]['values'][j]
            }
            download_data.push(single_data)
        }

        const data = download_data
        const fileName = this.state.company_name + '_financial_report'
        const exportType = 'xls'
        exportFromJSON({ data, fileName, exportType })
    }

    timeout = (delay) => {
        return new Promise(res => setTimeout(res, delay));
    }

    startLoading = () => {
        this.setState({ open_progress_bar: true });
    }

    stopLoading = () => {
        this.setState({ open_progress_bar: false });
    }

    handleReset = () => {
        this.setState(initialState)
    }

    render() {
        return (
            <div className="pt-2">
                <div className='row'>
                    <div className='col-md-6'>
                        <FormControl size="small" variant="outlined" className="w-100 mt-2">
                            <InputLabel>Company Name</InputLabel>
                            <Select value={this.state.company_name} onChange={this.handleCompanyName} label="Company Name">
                                {this.state.company_name_list.map((k, i) => (
                                    <MenuItem key={i} value={this.state.company_name_list[i]['value']}>
                                        {this.state.company_name_list[i]['display']}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        <iframe
                            className='w-100 border mt-2 scroll'
                            style={{ height: 'calc(100vh - 13rem)' }}
                            src={this.state.html_data}
                            key={this.state.html_data}
                        />
                    </div>
                    <div className='col-md-6'>
                        <div className='text-right'>
                            <Tooltip title="Download Report" placement='left-start' arrow>
                                <Fab
                                    size="small"
                                    className='small_button text-white mt-2 mr-2'
                                    onClick={() => this.downloadReport()}
                                    hidden={this.state.download_hidden}>
                                    <GetApp fontSize='small' />
                                </Fab>
                            </Tooltip>
                            <Fab
                                size="small"
                                className='small_button text-white mt-2 mr-2'
                                style={{ visibility: 'hidden' }}
                                hidden={!this.state.download_hidden} >
                                <GetApp fontSize='small' />
                            </Fab>
                        </div>
                        <TableContainer className='border scroll mt-2' component={Paper} style={{ height: 'calc(100vh - 13rem)' }} hidden={this.state.download_hidden}>
                            <Table size="medium" stickyHeader>
                                <TableHead>
                                    <TableRow>
                                        <TableCell />
                                        <TableCell align="right">
                                            <b>{this.state.header_data['values'][0]}</b>
                                        </TableCell>
                                        <TableCell align="right">
                                            <b>{this.state.header_data['values'][1]}</b>
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {this.state.data.map((k, i) => (
                                        <TableRow hover className='pointer' key={i} onClick={() => this.getHTMLFile(this.state.data[i]['type'], this.state.data[i]['lines'])} >
                                            <TableCell>
                                                <b>{this.state.data[i]['key']}</b>
                                            </TableCell>
                                            <TableCell align="right">
                                                {this.state.data[i]['values'][0]}
                                            </TableCell>
                                            <TableCell align="right">
                                                {this.state.data[i]['values'][1]}
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </div>
                </div>
                <Dialog
                    open={this.state.dialog_add_pg_no}
                    onClose={() => this.setState({ dialog_add_pg_no: false })}>
                    <DialogTitle>Enter Page Number</DialogTitle>
                    <DialogContent>
                        <TextField
                            size="small"
                            variant="outlined"
                            className="w-100"
                            value={this.state.pg_no}
                            onChange={(evt) => this.setState({ pg_no: evt.target.value.replace(/[^0-9]+/g, "") })}
                            // onKeyPress={this.keyPress}
                            InputProps={{
                                startAdornment:
                                    <InputAdornment position="start">
                                        Page -
                                    </InputAdornment>,
                            }}
                            inputProps={{ maxLength: 4 }}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button size='small' variant="outlined" onClick={() => this.setState({ dialog_add_pg_no: false })}
                            color="default">
                            <b>Cancel</b>
                        </Button>
                        <Button size='small' variant="outlined" onClick={this.addNewPage}
                            color="primary">
                            <b>Add</b>
                        </Button>
                    </DialogActions>
                </Dialog>
                <Backdrop className={this.props.classes.backdrop} open={this.state.open_progress_bar}>
                    <CircularProgress size={80} color="inherit" />
                </Backdrop>
            </div>
        )
    }
}

export default withStyles(useStyles)(FinancialParser);